<template>
    <div class="verScreenCode">
        <div class="promotion">
            <img style="width: 900px" :src="imgUrl" alt="">
        </div>
        <div class="flexBox">
            <div class="leftBox box">
                <div class="title">全国船员业务咨询热线:400-856-6180</div>
                <div class="title">天津海事局船员服务电话:022-58870123</div>
            </div>
            <div class="rightBox box">
                <div class="title">中华全国总工会职工维权热线:12351</div>
                <div class="title">i船员法律咨询服务热线:400-670-8858</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'verScreenCode',
    data() {
        return {
            BASECOVERURL: 'http://120.46.134.132:5238/webfile/',
            imgUrl: ''
        }
    },
    created() {
    },
    mounted() {
        this.$axios.get('api/directive/cms/lidiContentList?categoryId=147&pageIndex=1').then(
            res => {
                if (res.data.page.list.length > 0) {
                    const Suffix = JSON.parse(res.data.page.list[0].data).a_1
                    this.imgUrl = this.BASECOVERURL + Suffix
                }
            })
    },
    methods: {
    },
}
</script>
<style scoped>
.verScreenCode {
    width: 900px;
    /* background-color: #fff; */
    margin: auto;
}

.codeImg {
    width: 150px;
    height: 150px;
    /* background-color: rebeccapurple; */
}

.flexBox {
    width: 900px;
    display: flex;
    justify-content: space-around;
}

.title {
    font-size: 24px;
    line-height: 40px;
}

.box {
    width: 100rpx;
    height: 200rpx;
    text-align: left;
}
</style>