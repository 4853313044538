<template>
    <div class="parent">
        <a-list item-layout="horizontal" :data-source="DisposeList">
            <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta>
                    <a slot="title" :href="item.url"><span>{{ item.title }}</span></a>
                </a-list-item-meta>
            </a-list-item>
        </a-list>
    </div>
</template>
<script>
export default {
    name: 'verScreenText',
    data() {
        return {
            BASECOVERURL: 'http://120.46.134.132:5238/webfile/',
            DisposeList: [],
        }
    },
    props: {
        list: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
    },
    beforeMount() {
        this.DisposeList = this.list.slice(0, 9)
        // console.log(this.DisposeList);
    },
    mounted() {
        // console.log('component--mounted');
    },
}
</script>
<style scoped>
.verScreenText {
    width: 900px;
    height: 850px;
    margin: auto;
    margin-top: 50px;
}

.ant-carousel>>>.slick-slide {
    text-align: center;
    height: 850px;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel>>>.slick-dots-bottom {
    bottom: 320px;
}

.ant-carousel>>>.custom-slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 50px;
    color: #fff;
    background-color: rgba(0, 8, 18, 0.5);
    opacity: 0.7;
    top: 30%;
}

.ant-carousel>>>.custom-slick-arrow:before {
    display: none;
}

.ant-carousel>>>.custom-slick-arrow:hover {
    opacity: 0.5;
    background-color: rgba(0, 8, 18, 0.5);
}

.ant-carousel>>>.slick-slide h3 {
    color: #fff;
}

span {
    font-size: 24px;
    color: black;
    font-weight: 700;
}

.parent :deep(.ant-list-item-meta-title) {
    margin-left: 90px;
    text-align: left;
    line-height: 40px;
}
</style>