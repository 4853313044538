<template>
    <div class="verScreen">
        <!-- 轮播视频 -->
        <ver-screen-video></ver-screen-video>
        <!-- 轮播文字 -->
        <ver-screen-text v-if="list.length > 0" :list="list"></ver-screen-text>
        <!-- 联系方式 -->
        <ver-screen-code></ver-screen-code>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import verScreenVideo from '../../components/verScreenComponent/verScreenVideo.vue'
import verScreenText from '../../components/verScreenComponent/verScreenText.vue'
import verScreenCode from '../../components/verScreenComponent/verScreenCode.vue'
export default {
    name: 'verScreen',
    components: { verScreenVideo, verScreenText, verScreenCode },
    data() {
        return {
            list: [],
            videoList: [],
            noticeList: [],
            cmsOnlineDuration: {}
        }
    },
    beforeCreate() {
    },
    created() {
        // 图片  
        this.$axios.get('api/directive/cms/lidiContentList?categoryId=3&pageIndex=1').then(
            res => {
                // console.log(res.data.page.list);
                this.list = res.data.page.list
                // console.log(this.list);
            }
        )
        // 公告
        this.$axios.get('api/directive/cms/lidiContentList?categoryId=99&pageIndex=1').then(
            res => {
                // 把拿到的公告数据存到公告数组中
                this.noticeList = res.data.page.list
                // console.log(this.noticeList);
            }
        )
        // 使用竖屏发送请求  
    },
    mounted() {
        const id = localStorage.id;
        const startDate = localStorage.startDate;
        const endDate = localStorage.endDate;
        if (id) {
            this.$axios.get(`/api/directive/lidigroup/onlineDuration?id=${id}&startDate=${startDate}&endDate=${endDate}`).then(
                res => {
                    this.cmsOnlineDuration = res.data.cmsOnlineDuration
                }
            )
        }
        window.addEventListener('beforeunload', () => {
            localStorage.endDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
        });
        this.$axios.get(`api/directive/lidigroup/onlineDuration?startDate=${dayjs().format("YYYY-MM-DD HH:mm:ss")}`).then(
            res => {
                // console.log(res.data.cmsOnlineDuration);
                this.cmsOnlineDuration = res.data.cmsOnlineDuration
                localStorage.id = this.cmsOnlineDuration.id;
                localStorage.startDate = this.cmsOnlineDuration.startDate;
            }
        )
    }
}
</script>
<style scoped>
.verScreen {
    padding-top: 10px;
    width: 1080px;
    height: 1920px;
    background-color: rgba(123, 123, 123, 0.1);
}
</style>