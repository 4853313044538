import Vue from 'vue'
import VueRouter from 'vue-router'
import verScreen from '../views/verScreen/verScreen.vue'

Vue.use(VueRouter)

const routes = [

  {
    path:'',
    name:'verScreen',
    component:verScreen
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
