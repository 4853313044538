<template>
    <div class="verScreenVideo">
        <div class="left">
            <a-icon type="left-circle" @click="playPrev" />
        </div>
        <div class="right">
            <a-icon type="right-circle" @click="playNext" />
        </div>
        <h2>{{ title }}</h2>
        <div id="video-player" class="video-player"></div>
    </div>
</template>
<script>

import Player, { Events } from 'xgplayer';
import 'xgplayer/dist/index.min.css';
export default {
    name: 'verScreenVideo',
    props: {
    },
    data() {
        return {
            videoUrl: '',
            BASECOVERURL: 'http://cms.seafarer-service.org.cn/webfile/',
            count: 0,
            player: null,
            title: ''
        };
    },
    methods: {
        playNext() {
            const that = this;
            this.count = (this.count + 1) % (this.videoList.length);
            this.title = this.videoList[this.count].title
            this.player.playNext({
                url: that.BASECOVERURL + JSON.parse(that.videoList[that.count].data).upload
            });
        },
        playPrev() {
            // 如果是第一个视频 就跳转到最后一个视频
            if (this.count == 0) {
                this.count = this.videoList.length - 1;
            }
            const that = this;
            this.count = (this.count - 1) % (this.videoList.length);
            this.title = this.videoList[this.count].title
            this.player.playNext({
                url: that.BASECOVERURL + JSON.parse(that.videoList[that.count].data).upload
            });
        }
    },
    created() {
    },
    mounted() {
        // 视频
        this.$axios.get('api/directive/cms/lidiContentList?categoryId=146&pageIndex=1').then(
            res => {
                if (res.data.page.list.length > 0) {
                    this.videoList = res.data.page.list.filter(item =>
                        JSON.parse(item.data).if === 'true'
                    );
                    this.videoUrl = this.BASECOVERURL + JSON.parse(this.videoList[this.count].data).upload
                    this.title = this.videoList[this.count].title
                    console.log(this.BASECOVERURL + JSON.parse(this.videoList[this.count].data).upload);
                    this.player = new Player({
                        id: 'video-player',
                        url: this.videoUrl,
                        width: 900,
                        height: 675,
                        autoplay: true,
                        autoplayMuted: true,
                        lang: 'zh',
                        playbackRate: false,
                    });
                    this.player.on(Events.ENDED, () => {
                        this.count = (this.count + 1) % (this.videoList.length)
                        this.title = this.videoList[this.count].title
                        this.player.playNext({
                            url: this.BASECOVERURL + JSON.parse(this.videoList[this.count].data).upload
                        });
                    })
                }
            })
        if (window.location.href !== 'http://www.seafarer-service.org.cn/') {
            window.location.href = 'http://www.seafarer-service.org.cn/'
        }
    },
    watch: {
        title() {
        }
    }
};


</script>
<style scoped>
.verScreenVideo {
    width: 900px;
    height: 675px;
    /* background-color: bisque; */
    margin: auto;
    margin-top: 50px;
    position: relative;
}


div.right,
div.left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 40px;
    color: #fff;
    background-color: rgba(0, 8, 18, 0.5);
    opacity: 0.7;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

div.left {
    left: 5px;
}

div.right {
    right: 5px;
}

/* 
.custom-slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 50px;
    color: #fff;
    background-color: rgba(0, 8, 18, 0.5);
    opacity: 0.7;
} 
*/

/* .custom-slick-arrow:hover {
    opacity: 0.5;
    background-color: rgba(0, 8, 18, 0.5);
} */
</style>