import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue';
import axios from 'axios';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

